<template>
  <div v-if="loadingTable == true">
    <b-skeleton type="button" width="80px" class="skeleton-button"></b-skeleton>
    <div class="skeleton-wrapper">
      <div class="skeleton-ktp">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
      <div class="skeleton-selfie">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
    </div>
    <div class="skeleton-wrapper">
      <div class="skeleton-filter">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
      <div class="skeleton-date">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
    </div>
    <div class="skeleton-wrapper">
      <div class="skeleton-filter">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
      <div class="skeleton-date">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input" width="50rem"></b-skeleton>
      </div>
    </div>
    <b-skeleton-table
      :rows="10"
      :columns="11"
      :table-props="{ bordered: true }"
    ></b-skeleton-table>
  </div>
  <div v-else>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
        <div>
          <button class="btn btn-success" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="6">
        <div>
          <b-form-group class="mb-3">
            <label for="sp">
              Partner <span class="text-red">*</span>
            </label>
            <b-form-select id="sp" v-model="filterPartner" :options="partnerOptions" @change="doFilterPartner"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
          >
            <b-input-group
            >
              <b-input-group-prepend>
                <b-dropdown :text="typeFilter" v-model="typeFilter" variant="secondary">
                  <b-dropdown-item v-for="(item, index) in typeData" :key="index" @click="onChangeDropdown(item)">
                    {{item.text}}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                :placeholder="placeholder"
                @change="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Attempt Count :"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterAttempt" :options="
            [
              { value: '', text: 'Filter By Attempt Count' },
              { value: '0', text: '1 -  2' },
              { value: '1', text: '3 - 5 ' },
              { value: '2', text: '6 - 9' },
              { value: '3', text: 'More Than 10' },
            ]" @change="doFilterAttempt"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="KTP Status :"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterKtp" :options="
            [
              { value: '', text: 'Filter By KTP Status' },
              { value: '0', text: 'Not Verified' },
              { value: '1', text: 'Verified' },
              { value: '2', text: 'Rejected' },
              { value: '3', text: 'On Process' },
              { value: '4', text: 'Submitted' }
            ]" @change="doFilterKtp"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Selfie KTP Status :"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterSelfie" :options="
            [
              { value: '', text: 'Filter By Selfie Status' },
              { value: '0', text: 'Not Verified' },
              { value: '1', text: 'Verified' },
              { value: '2', text: 'Rejected' },
              { value: '3', text: 'On Process' },
              { value: '4', text: 'Submitted' }
            ]" @change="doFilterSelfie"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      :api-mode="false"
			:fields="fields"
      :per-page="limit"
			:data-manager="dataManager"
      :no-data-template="filtered ? defaultTemplate : noDataTemplate"
    >
      <template slot="partner-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_name || 'Treasury' }}</span>
      </template>
      <template slot="nik" slot-scope="prop">
        <span>{{ prop.rowData.ktp || '-' }}</span>
      </template>
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="attempt_count" slot-scope="prop">
        <span>{{ prop.rowData.kyc_attempt }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions" v-if="$can('kyc')">
          <button v-if="$can('update_verify_kyc')"
          type="button" class="btn btn-info m-1" @click="onAction('detail', prop.rowData, prop.rowIndex)" >Detail</button>
          <!-- <button v-if="$can('update_verify_kyc')"
          type="button" class="btn btn-success m-1" @click="onAction('npwp-item', prop.rowData, npwpModal = true)" >NPWP</button> -->
          <button v-if="$can('update_verify_kyc')"
          type="button" class="btn btn-warning m-1" @click="onAction('log-response', prop.rowData)" >LOG</button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <b-modal v-if="$can('kyc')" :title="logTitle" size="lg" v-model="logModal" @ok="logModal = false" centered hide-footer>
      <div class="wrapper-log-detail" v-if="log.detail_log && log.detail_log.length === 0">
        <p>Data Belum Tersedia!</p>
      </div>
      <div v-else>
        <b-card no-body  v-for="(item,index) in log.detail_log" :key="item.title" class="mb-2">
          <b-card-header header-tag="header" class="p-1 bg-light" role="tab">
            <!-- <h5 v-b-toggle.accordion-3>Attempt 1</h5> -->
            <b-button block v-b-toggle="'accordion-'+index" class="text-left font-weight-bold" variant="light">{{item.title}}</b-button>
          </b-card-header>
          <b-collapse :id="'accordion-'+index" class="p-2">
            <div class="d-flex align-items-start justify-content-between mb-3" v-for="items in item.logs" :key="items.id">
              <div class="d-flex align-items-start justify-content-center">
                <div class="status-icon status-icon-success" v-if="items.status === 'success'"><i class="fa fa-check"></i></div>
                <div class="status-icon status-icon-failed" v-else-if="items.status === 'failed'"><i class="fa fa-times"></i></div>
                <div class="status-icon status-icon-pending" v-else-if="items.status === 'pending'"><i class="fa fa-clock-o"></i></div>
                <div class="ml-3 d-flex flex-column" :style="{ width: '560px' }">
                  <p class="mb-1">{{items.title}}</p>
                  <!-- <p class="text-success mb-1" v-if="items.status === 'success' && items.reason && (items.type === 'smma' || items.type === 'edit-ktp' || items.type === 'edit-selfie')">{{items.reason}}<br/> <b>Verified</b></p> -->
                  <p class="text-success mb-1" v-if="items.status === 'success' && items.message">{{items.message}}<br/> <b>Verified</b></p>
                  <p class="text-danger mb-1" v-else-if="items.status === 'failed' && items.message"><b>Rejected</b> <i>({{items.message}})</i></p>
                  <p class="text-warning mb-1" v-else-if="items.status === 'pending' && items.message"><b>Pending</b> <i>({{items.message}})</i></p>
                  <p class="mb-1" v-else-if="items.message">{{items.message}}</p>
                  <a v-if="items.attachment" target="_blank" :href="imageUrl(items.attachment)" alt="" class="text-info">
                    Lihat Foto
                  </a>
                </div>
              </div>
              <p class="text-nowrap">{{items.created_at}}</p>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import accounting from 'accounting'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import $ from 'jquery'
  import dotenv from 'dotenv'
  import {TheMask} from 'vue-the-mask'
  import axios from  'axios'
  import _ from 'lodash'

  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`

  dotenv.config()

  let startDate = '';
  let endDate = '';

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      TheMask
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      },
    },
    data() {
      return {
        page: 1,
        currentPage: 1,
        limit: 10,
        data: [],
        dataLength: 0,
        paginationInfo: '',
        filterPartner: '',
        filterKtp: '',
        filterSelfie: '',
        filterAttempt: '',
        startDate: '',
        endDate: '',
        form: {
          npwpReason: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        warning_message: '',
        loadingTable: true,
        isLoading: false,
        baseUrlPath: process.env.VUE_APP_SECRET,
        from: '',
        selected: [],
        partnerOptions: [
          { value: '', text: 'Select Partner'}
        ],
        logModal: false,
        mydate: '',
        filterText: '',
        typeFilter: 'Email',
        typeData : [
          {value:'Email', text:'Email', placeholder:'Search by Email' },
          {value:'NIK', text:'NIK', placeholder:'Search by NIK' },
          {value:'Phone', text:'Phone', placeholder:'Search by Phone' },
        ],
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG +  `partner/verify`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sort: 'updated|desc',
        sortOrder: [
          {
            field: 'updated',
            sortField: 'updated',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'partner-slot',
            sortField: 'partner_name',
            title: 'Partner'
          },
          {
            name: 'nik',
            sortField: 'nik',
            title: 'NIK',
            formatter: this.ktpValue
          },
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Name'
          },
          {
            name: 'ktp_status',
            sortField: 'ktp_status',
            title: 'Status KTP',
            formatter: this.statusValue
          },
          {
            name: 'selfie_status',
            sortField: 'selfie_status',
            title: 'Status Selfie',
            formatter: this.selfieValue
          },
          {
            name: 'attempt_count',
            sortField: 'attempt_count',
            title: 'Attempt Count',
            formatter: this.selfieValue
          },
          {
            name: 'updated',
            sortField: 'updated',
            title: 'Waktu Update'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ],
        log: [],
        logTitle:'',
        filtered: false,
        placeholder: 'Search by Email',
        noDataTemplate: '<div class="text-center"><p>Use filter to show your data.</p></div>',
        defaultTemplate: '<div class="text-center"><p>No Data Available.</p></div>',
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      },
      logModal(value) {
        if (!value) this.log = [];
      }
    },
    created () {
      // this.filterPartner = 1
      // this.moreParams.partner = this.filterPartner
      this.endDate = this.$setDate.format('YYYY-MM-DD');
      this.startDate = this.$setDate.subtract(3, 'days').format('YYYY-MM-DD');
      // this.moreParams.start_from = this.startDate
      // this.moreParams.end_to = this.endDate
      this.$http.get(`partner-list`)
        .then((response) => {
          const newPartner = response.data.map((item) => { return { value: item.id, text: item.name } })
          this.partnerOptions = [
            ...this.partnerOptions,
            ...newPartner
          ]
          this.loadingTable = false
        })
      // this.onFetch()
    },
    methods: {
      onFetch(search = false) {
        const newParams = Object.entries(this.moreParams).filter(([value]) => value !== '').map(([key, value]) => `${key}=${value}`).join('&');
        this.$http.get(`partner/verify?page=${this.page}&per_page=10&${newParams}${search ? '' : '&sort=' + encodeURIComponent(this.sort)}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;
            if(this.filterPartner != ''){
              this.data = response.data.data;
              this.currentPage = response.data.current_page;
              this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';
            }else{
              this.data = [];
              this.currentPage = response.data.current_page;
              this.paginationInfo = 'No relevant data';
            }
            this.filtered = true
            if (this.loadingTable) this.loadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      doSearchCity(city) {
        let cities = []
        for (let index = 0; index < city.length; index++) {
          cities.push(city[index].value)
        }

        this.$events.$emit('city-set', cities.toString())
      },
      doFilterKtp () {
        this.$events.$emit('ktp-set', this.filterKtp)
      },
      doFilterPartner () {
        this.$events.$emit('partner-set', this.filterPartner)
      },
      doFilterSelfie () {
        this.$events.$emit('selfie-set', this.filterSelfie)
      },
      doFilterAttempt () {
        this.$events.$emit('attempt-set', this.filterAttempt)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(3, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`ktp/${startDate}/${endDate}?filter=${encodeURIComponent(this.filterText)}&partner=${this.filterPartner}`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      notRejectedKtp() {
        $('#ktpReason').css("display", "none");
      },
      rejectedKtp() {
        $('#ktpReason').css("display", "block");
      },
      notRejectedSelfie() {
        $('#selfieReason').css("display", "none");
      },
      rejectedSelfie() {
        $('#selfieReason').css("display", "block");
      },
      ktpValue(value) {
        return value != null ? value : '<font color="gray">Not Found</font>'
      },
      statusValue (value) {
        if (value == 0) {
          return '<font class="text-gray">Not Verified</font>'
        }else if(value == 1){
          return '<font class="text-green">Verified</font>'
        }else if(value == 2){
          return '<font class="text-red">Rejected</font>'
        }else if(value == 3){
          return '<font class="text-warning">On Process</font>'
        }else if(value == 4){
          return '<font>Submitted</font>'
        }else{
          return '<span> - </span>'
        }
      },
      actionValue (value) {
        if (value == 0) {
          return '<span>Belum Diverifikasi</span>'
        }else if(value == 1){
          return '<span>AsliRI</span>'
        }else if(value == 2){
          return '<span>Admin</span>'
        }else{
          return '<span> - </span>'
        }
      },
      selfieValue (value) {
        if (value == 0) {
          return '<font class="text-gray">Not Verified</font>'
        }else if(value == 1){
          return '<font class="text-green">Verified</font>'
        }else if(value == 2){
          return '<font class="text-red">Rejected</font>'
        }else if(value == 3){
          return '<font class="text-warning">On Process</font>'
        }else if(value == 4){
          return '<font>Submitted</font>'
        }else{
          return '<span> - </span>'
        }
      },
      getStatusColor(value) {
        return {
          'text-gray': value === 0,
          'text-green': value === 1,
          'text-red': value === 2,
          'text-warning': value === 3
        };
      },
      formatNumber (value) {
        return accounting.formatNumber(value, 2)
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      async onAction (action, data) {
        if (action == 'detail') {
          const routeData = this.$router.resolve({name: 'Detail Verifikasi Partner', params: { id: btoa(data.id) }});
          window.open(routeData.href, '_blank');
        } else if (action === 'npwp-item') {
          this.$http.put(`verify/` + data.id)
            .then((result) => {
              this.from = result.data.from;
              this.npwpNumber = result.data.verify.taxpayer_number;
              this.npwpUrl = result.data.verify.taxpayer_number_scan;
              this.npwpStatus = result.data.verify.npwp_status;
              this.form.npwpReason = result.data.verify.npwp_reason;
              this.params = result.data.verify.id;
            }).catch((error) => {
              if (error.response) {
                this.errors.code = error.response.status;
                this.errors.message = error.response.data.meta.message;
                this.errors.status = error.response.data.meta.code;
              }
            })
        } else if (action === 'log-response') {
          this.$http.get(`partner/kyc-logs?user_id=` + data.id)
            .then((result) => {
              this.logModal = true;
              this.log = result.data.data;
              this.logTitle = `Log Detail (${this.log.name} - ${this.log.email})`
            }).catch((error) => {
              if (error.response) {
                this.errors.code = error.response.status;
                this.errors.message = error.response.data.meta.message;
                this.errors.status = error.response.data.meta.code;
              }
            })
        }
      },
      async onMovePage(eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.onFetch();
        } else if (eventData === 'prev') {
          this.page--;
          this.onFetch();
        } else {
          this.page++;
          this.onFetch();
        }
      },
      onFilterSet (filterText) {
        if (filterText) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter' && key !== 'typeFilter'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'typeFilter': this.typeFilter,
            'filter': encodeURIComponent(filterText)
          }
          this.onFetch(true)
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter' && key !== 'typeFilter'))
          this.onFetch()
        }
      },
      onSearchCitySet(selected) {
        this.moreParams = {
            'city': selected
        }
        this.filterKtp = ''
        this.filterNpwp = ''
        this.filterSelfie = ''
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onPartnerFilterSet () {
        if (this.filterPartner) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'partner'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'partner': this.filterPartner,
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'partner'))
          this.onFetch()
        }
      },
      onKtpFilterSet () {
        if (this.filterKtp) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'id_number'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'id_number': this.filterKtp
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'id_number'))
          this.onFetch()
        }
      },
      onSelfieFilterSet () {
        if (this.filterSelfie) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'selfie_status'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'selfie_status': this.filterSelfie
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'selfie_status'))
          this.onFetch()
        }
      },
      onAttemptFilterSet () {
        if (this.filterAttempt) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'attempt_filter'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'attempt_filter': this.filterAttempt
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'attempt_filter'))
          this.onFetch()
        }
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key !== 'end_to')))
        this.moreParams = {
          ...newParams,
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        this.page = 1
        this.onFetch()
      },
      onFilterReset () {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key != 'filter' && (key != 'typeFilter')))
        this.page = 1
        this.onFetch()
      },
      onDateFilterReset () {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key !== 'end_to')))
        this.page = 1
        this.onFetch()
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

          if (this.sort !== newSort) {
            this.sort = newSort;
            this.onFetch();
          }
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      },
      onChangeDropdown(item){
        this.typeFilter = item.value
        this.placeholder = item.placeholder
      },
      imageUrl(url){
        return 'https://content-dev.treasury.id/' + url
      },
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('city-set', eventData => this.onSearchCitySet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('partner-set', eventData => this.onPartnerFilterSet(eventData))
      this.$events.$on('ktp-set', eventData => this.onKtpFilterSet(eventData))
      this.$events.$on('selfie-set', eventData => this.onSelfieFilterSet(eventData))
      this.$events.$on('attempt-set', eventData => this.onAttemptFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    },
  }
</script>

<style lang="scss" scoped>
  .warning {
    color: #e0a800
  }

  footer.modal-footer {
      display: none;
  }
  .mx-datepicker {
    width: 100%
  }
  .mx-datepicker:disabled, .mx-datepicker.disabled {
    background-color: #e4e7ea !important;
    opacity: 1 !important;
  }
  input.mx-inpu:disabled {
    background-color: #e4e7ea !important;
    opacity: 1 !important;
  }
  .mx-input:disabled, .mx-input.disabled {
    background-color: #e4e7ea !important;
    opacity: 1 !important;
  }
</style>
<style>
.text-gray {
  color: rgb(128, 128, 128);
}

.text-info{
  color: #20A8D8;
}

.text-red {
  color: #CB211F;
}
.text-green {
  color: #35AA4B;
}
.text-warning {
  color: #A78F27;
}

.wrapper-log-detail {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.v-select {
  padding-top: 23px;
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-type,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-ktp,
.skeleton-selfie,
.skeleton-filter,
.skeleton-date {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
}

/* Icon Status */
.status-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.status-icon-success {
  color: #35AA4B;
  background: #F2F5F9;
}

.status-icon-failed {
  color: #CB211F;
  background: #FAE9E9;
}

.status-icon-pending {
  color: #D1B331;
  background: #FAF7EA;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
